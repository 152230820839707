import { EntitySerializer } from '@models/entity.serializer';
import { Account } from './account';
import { AccountMetaSerializer } from './account-meta.serializer';

export class AccountSerializer implements EntitySerializer {
  private _accountMetaSerializer = new AccountMetaSerializer();

  public mapping: Map<keyof Account, string> = new Map([
    ['uid', 'uid'],
    ['name', 'name'],
    ['accountType', 'account_type'],
    ['createdAt', 'created_at'],
    ['updatedAt', 'updated_at'],
    ['lastSyncedAt', 'last_synced_at'],
    ['isValid', 'is_valid'],
    ['isShared', 'is_shared'],
    ['status', 'status'],
    ['authData', 'auth_data'],
    ['integrationUid', 'integration_uid'],
    ['userUid', 'user_uid'],
    ['workspaceUserUid', 'workspace_user_uid'],
    ['authType', 'auth_type'],
    ['setupSource', 'setup_source'],
    ['error', 'error']
  ]);

  toEntity(json: any): Account {
    const account: any = new Account();
    this.mapping.forEach((value, key, map) => {
      account[key] = json[value];
    });

    /**
     * AccountMeta serializer
     */
    const accountMeta = json['meta'];
    if (accountMeta) {
      account.meta = this._accountMetaSerializer.toEntity(accountMeta);
    }

    return account as Account;
  }

  toJSON(account: Account) {
    if (!account) {
      return null;
    }

    const obj: { [key: string]: any } = {};
    this.mapping.forEach((value, key, map) => {
      obj[value] = account[key];
    });

    return obj;
  }
}
