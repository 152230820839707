/// <reference types="chrome"/>
import { Injectable } from '@angular/core';
import { Nullable } from '@captaindatatech/captaindata-angular-lib/ts-helpers';
import { environment } from '@env/environment';
import { AccountService } from '@http/account.service';
import { Account } from '@models/account/account';
import { CptErrorResponse } from '@models/captain/error-response/cpt-error-response';
import { Integration } from '@models/integration/integration';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable({ providedIn: 'root' })
export class AppExtensionService {
  private _extensionInstalled: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  /**
   * Constructor
   */
  constructor(private _accountService: AccountService) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------
  get extensionInstalled$() {
    return this._extensionInstalled.asObservable();
  }

  set extensionInstalled(value: boolean) {
    this._extensionInstalled.next(value);
  }

  getExtensionInstalledValue(): boolean {
    return this._extensionInstalled.getValue();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Send a message to the extension to check if it is installed
   */
  private async _fetchExtension(): Promise<boolean> {
    return await new Promise(async (resolve, reject) => {
      try {
        console.log(environment.extensionId);
        chrome.runtime.sendMessage(
          environment.extensionId,
          {
            message: 'captain_fetch_extensionId',
            version: 'v4'
          },
          (res) => {
            let isInstalled: boolean = false;
            if (res) {
              if (res.extensionId) {
                isInstalled = true;
              }
            }
            resolve(isInstalled);
          }
        );
      } catch (err) {
        resolve(false);
      }
    });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Check if Chrome Extension is installed and update Subject accordingly
   */
  async checkExtensionInstalled() {
    this.extensionInstalled = await this._fetchExtension();
  }

  /**
   * Remove an accont from the locale storage of the  Extension
   */
  removeExtensionAccount(account: Account) {
    try {
      chrome.runtime.sendMessage(environment.extensionId, {
        message: 'captain_remove_account',
        accountUid: account.uid,
        version: 'v4'
      });
    } catch (err) {}
  }

  /**
   * Fetch cookies for a given account & a given integration
   */
  async fetchCookiesViaExtension(
    integration: Integration,
    account: Account
  ): Promise<any> {
    return await new Promise(async (resolve, reject) => {
      try {
        chrome.runtime.sendMessage(
          environment.extensionId,
          {
            message: 'captain_fetch_cookies',
            integrationUid: integration.uid,
            integrationCookies: JSON.stringify(integration.authSpecification),
            baseUrl: integration.baseUrl,
            accountUid: account?.uid,
            version: 'v4'
          },
          async (res) => {
            if (res) {
              if (
                res.cookies &&
                (res.cookies['li_at'] || res.cookies['li_a'])
              ) {
                resolve({ cookies: res.cookies });
              } else if (
                res.error ||
                (res.cookies && !res.cookies['li_at'] && !res.cookies['li_a'])
              ) {
                reject(
                  'It appears you are not connected to ' +
                    integration.name +
                    '.'
                );
              } else {
                reject(null);
              }
            } else {
              reject(
                "It seems you didn't install Captain Data extension. Please install it!"
              );
            }
          }
        );
      } catch (err) {
        reject(null);
      }
    });
  }

  /**
   * Install Chrome Extension & subscribe to installation
   */
  installExtension() {
    const count = 0;
    window.open(environment.extensionURL, '_blank');
    const intervalId = setInterval(async () => {
      if (await this._fetchExtension()) {
        this.extensionInstalled = true;
        clearInterval(intervalId);
      }
      if (count >= 120) {
        clearInterval(intervalId);
      }
    }, 1000);
  }

  /**
   * Refresh account
   */
  async refreshAccount(
    integration: Integration,
    account: Account
  ): Promise<Nullable<Account>> {
    return await new Promise(async (resolve, reject) => {
      try {
        const cookies = await this.fetchCookiesViaExtension(
          integration,
          account
        );
        if (cookies) {
          account.authData = cookies;
          this._accountService.updateAuthData(account).subscribe({
            next: (res) => {
              resolve(res.content);
            },
            error: (err: CptErrorResponse) => {
              reject(err.messageToString());
            }
          });
        }
      } catch (err) {
        reject(err);
      }
    });
  }
}
