import { EntitySerializer } from '@models/entity.serializer';
import { WorkspaceUserSerializer } from '@models/workspace-user/workspace-user.serializer';
import { User } from './user';

export class UserSerializer implements EntitySerializer {
  private _workspaceUserSerializer = new WorkspaceUserSerializer();

  public mapping: Map<keyof User, string> = new Map([
    ['uid', 'uid'],
    ['name', 'name'],
    ['country', 'country'],
    ['createdAt', 'created_at'],
    ['updatedAt', 'updated_at'],
    ['registeredAt', 'registered_at'],
    ['email', 'email'],
    ['firstName', 'first_name'],
    ['lastName', 'last_name'],
    ['fullName', 'full_name'],
    ['hasEmailPro', 'has_email_pro'],
    ['inviteSent', 'invite_sent'],
    ['phoneNumber', 'phone_number'],
    ['pictureUrl', 'picture_url'],
    ['signupSource', 'signup_source'],
    ['sourceType', 'source_type'],
    ['displayTimezone', 'date_timezone'],
    ['proxyTimezone', 'proxy_timezone'],
    ['role', 'role_name'],
    ['workspaceUser', 'workspace_user'],
    ['currentWorkspaceUid', 'current_workspace_uid'],
    ['isPending', 'is_pending']
  ]);

  toEntity(json: any): User {
    const user: any = new User();
    if (json) {
      this.mapping.forEach((value, key, map) => {
        user[key] = json[value];
      });

      const workspaceUser = json['workspace_user'];
      if (
        workspaceUser &&
        Array.isArray(workspaceUser) &&
        workspaceUser.length === 1
      ) {
        user.workspaceUser = this._workspaceUserSerializer.toEntity(
          workspaceUser[0]
        );
      }
    }
    return user as User;
  }

  toJSON(user: User) {
    if (!user) {
      return null;
    }

    const obj: { [key: string]: any } = {};
    this.mapping.forEach((value, key, map) => {
      obj[value] = user[key];
    });

    return obj;
  }
}
