import { Nullable } from '@captaindatatech/captaindata-angular-lib/ts-helpers';
import { Entity } from '@models/entity';
import { User } from '@models/user/user';
import { AccountMeta } from './account-meta';

export class Account extends Entity {
  integrationUid: string;
  workspaceUserUid: string;
  userUid: string;
  user: User;
  name: string;
  accountType: 'NORMAL' | 'BUNDLED';
  createdAt: Date;
  updatedAt: Date;
  lastSyncedAt: Date;
  isValid: boolean;
  isShared: boolean;
  status: 'VALID' | 'INVALID' | 'PENDING' | 'LIMIT_REACHED';
  authData: AuthData;
  level: Nullable<string>;
  meta?: AccountMeta;
  authType: 'BASIC' | 'OAUTH' | 'COOKIES' | 'APIKEY';
  setupSource: 'MANUAL' | 'EXTENSION' | 'SHARED' | 'API';
  error: string;
}

export type AuthData = {
  token?: string;
  basic?: AuthDataBasic;
  cookies?: any;
};

export type AuthDataBasic = {
  username: string;
  password: string;
};
