<div
  class="navbar-wrapper py-xl2 bg-grey-25 flex flex-col h-screen justify-between">
  <div class="">
    <!-- Dropdown -->
    <cpt-overlay-panel
      #op
      (onShow)="onWorkspaceSearch('')"
      (onHide)="onOverlayPanelHide()">
      <ng-template cptTemplate="content">
        <!-- Dropdown wrapper -->
        <div class="navbar-dropdown bg-white border-grey-200 border rounded-lg">
          <!-- Email + Workspaces -->
          <div class="px-lg border-b border-grey-200 py-lg">
            <div class="text-b3 font-normal text-grey-400 px-lg pb-md">
              {{ user?.email }}
            </div>

            <!-- Search bar -->
            <div class="w-full" *ngIf="'read' | able: 'all_workspaces'">
              <cpt-search
                inputStyleclass="w-full"
                placeholder="Search workspaces"
                (valueChanged)="onWorkspaceSearch($event)"></cpt-search>
            </div>
            <!-- Workspaces list -->
            <div class="max-h-[120px] overflow-y-auto">
              <div
                *ngFor="
                  let wk of filteredWorkspaces;
                  trackBy: trackWorkspacesByFn
                "
                [ngClass]="{ 'bg-grey-100': wk.uid === workspace?.uid }"
                (click)="selectWorkspace(wk)"
                class="flex items-center justify-between mt-md py-md px-lg rounded-lg cursor-pointer">
                <div class="flex items-center">
                  <img
                    class="mr-lg scale-100 w-[2rem] h-[2rem]"
                    [src]="
                      wk.logoUrl
                        ? wk.logoUrl
                        : 'https://picsum.photos/2400/2400'
                    " />
                  <div class="flex flex-col">
                    <div
                      class="text-h4 whitespace-nowrap text-ellipsis overflow-hidden">
                      {{ wk.name }}
                    </div>
                    <div class="text-b3 text-grey-500">Free plan</div>
                  </div>
                </div>

                <cpt-svg-icon
                  class="flex-shrink-0"
                  key="valid"
                  *ngIf="wk.uid === workspace?.uid"></cpt-svg-icon>
              </div>
            </div>
          </div>
          <!-- Workspace and profile settings -->
          <div class="px-lg border-b border-grey-200">
            <a
              routerLink="/profile"
              class="flex items-center mt-sm p-lg cursor-pointer hover:bg-grey-100"
              (click)="op.toggle(false)">
              <cpt-svg-icon key="user"></cpt-svg-icon>
              <div class="text-b1 ml-lg">Manage profile</div>
            </a>
          </div>
          <!-- Dev toolkit  -->
          <div
            class="px-lg border-b border-grey-200"
            *ngIf="user && 'write' | able: 'admin_specs'">
            <div class="text-b3 font-normal text-grey-400 px-lg mt-md">
              Dev toolkit
            </div>

            <div class="flex items-center py-md cursor-pointer">
              <div class="text-b1 ml-lg w-1/3">Branch</div>
              <cpt-dropdown
                class="w-2/3"
                styleClass="w-full !pt-lg !pb-lg !pl-xl !pr-lg"
                [options]="headlessbranchOptions"
                (onChange)="
                  updateAdminSpecifictions($event.value, 'headlessBranchName')
                "
                [ngModel]="user?.adminSpecifications?.headlessBranchName">
              </cpt-dropdown>
            </div>
            <div class="flex items-center py-md cursor-pointer">
              <div class="text-b1 ml-lg w-1/3">API version</div>
              <cpt-input-text
                id="email"
                class="my-md w-2/3"
                inputStyleClass="text-grey-900"
                placeholder="API version"
                (ngModelChange)="
                  updateAdminSpecifictions($event, 'headlessContainerVersion')
                "
                [ngModel]="
                  user?.adminSpecifications?.headlessContainerVersion
                " />
            </div>
            <div class="flex items-center py-md cursor-pointer">
              <div class="text-b1 ml-lg w-1/3">Serveless function</div>
              <cpt-dropdown
                class="w-2/3"
                styleClass="w-full !pt-lg !pb-lg !pl-xl !pr-lg"
                [options]="serverlessVersionOptions"
                (onChange)="
                  updateAdminSpecifictions($event.value, 'serverlessVersion')
                "
                [ngModel]="user?.adminSpecifications?.serverlessVersion">
              </cpt-dropdown>
            </div>
          </div>
          <!-- Sign out -->
          <div class="px-lg">
            <a class="flex items-center p-lg cursor-pointer" (click)="logout()">
              <cpt-svg-icon key="logout"></cpt-svg-icon>
              <div class="text-b1 ml-lg">Log out</div>
            </a>
          </div>
        </div>
      </ng-template>
    </cpt-overlay-panel>

    <!-- Workspace -->
    <div class="workspace-wrapper px-xl2">
      <div
        class="workspace flex items-center w-fit hover:bg-grey-100 border border-transparent rounded-lg py-sm cursor-pointer px-md"
        [ngClass]="{ 'bg-grey-100': op.overlayVisible }"
        (click)="op.toggle($event)">
        <img
          class="workspace-image mr-lg scale-100 w-[1.5rem] h-[1.5rem]"
          [src]="workspace?.logoUrl ?? 'https://picsum.photos/2400/2400'" />
        <div class="text-b1 workspace-label">
          <span class="font-medium">{{ workspace?.name }}</span>
        </div>
        <cpt-svg-icon key="chevron-down"></cpt-svg-icon>
      </div>
    </div>
    <!-- Menu items -->
    <div
      class="navbar-items flex flex-col py-xl3 text-grey-500 border-b border-grey-200 px-xl2">
      <a
        class="navbar-item flex items-center rounded-md py-md px-lg my-sm hover:bg-grey-100 hover:text-grey-900"
        [showDelay]="1000"
        cptTooltip="Settings & Users"
        routerLinkActive="bg-grey-100 text-grey-900"
        [routerLink]="['settings']">
        <cpt-svg-icon key="settings2" size="mdlg"></cpt-svg-icon>
        <div class="navbar-item-label text-b2 ml-lg">
          <span class="font-medium">Settings & users</span>
        </div>
      </a>
      <a
        class="navbar-item flex items-center rounded-md py-md px-lg my-sm hover:bg-grey-100 hover:text-grey-900"
        [showDelay]="1000"
        cptTooltip="Accounts"
        routerLinkActive="bg-grey-100 text-grey-900"
        [routerLink]="['integrations', 'linkedin']">
        <cpt-svg-icon key="integrations" size="mdlg"></cpt-svg-icon>
        <div class="navbar-item-label text-b2 ml-lg">
          <span class="font-medium">Accounts</span>
        </div>
      </a>
      <!-- <a
        class="navbar-item flex items-center rounded-md py-md px-lg my-sm hover:bg-grey-100 hover:text-grey-900"
        [showDelay]="1000"
        cptTooltip="Integrations"
        routerLinkActive="bg-grey-100 text-grey-900"
        [routerLink]="['integrations']">
        <cpt-svg-icon key="integrations" size="mdlg"></cpt-svg-icon>
        <div class="navbar-item-label text-b2 ml-lg">
          <span class="font-medium">Integrations</span>
        </div>
      </a> -->
      <!-- <a
        class="navbar-item flex items-center rounded-md py-md px-lg my-sm hover:bg-grey-100 hover:text-grey-900"
        [showDelay]="1000"
        cptTooltip="Templates Library"
        routerLinkActive="bg-grey-100 text-grey-900"
        [routerLink]="['templates-library']">
        <cpt-svg-icon key="library" size="mdlg"></cpt-svg-icon>
        <div class="navbar-item-label text-b2 ml-lg" [class]>
          <span class="font-medium">Templates Library</span>
        </div>
      </a> -->
      <a
        class="navbar-item flex items-center rounded-md py-md px-lg my-sm hover:bg-grey-100 hover:text-grey-900"
        [showDelay]="1000"
        cptTooltip="Actions Library"
        routerLinkActive="bg-grey-100 text-grey-900"
        [routerLink]="['actions-library']">
        <cpt-svg-icon key="credits" size="mdlg"></cpt-svg-icon>
        <div class="navbar-item-label text-b2 ml-lg" [class]>
          <span class="font-medium">Actions Library</span>
        </div>
      </a>

      <!-- <a
        class="navbar-item flex items-center rounded-md py-md px-lg my-sm hover:bg-grey-100 hover:text-grey-900"
        [showDelay]="1000"
        cptTooltip="Activity"
        routerLinkActive="bg-grey-100 text-grey-900"
        [routerLink]="['activity']">
        <cpt-svg-icon key="activity" size="mdlg"></cpt-svg-icon>
        <div class="navbar-item-label text-b2 ml-lg">
          <span class="font-medium">Activity</span>
        </div>
      </a> -->

      <!-- Workflows -->
      <!-- <a
        class="navbar-item flex items-center rounded-md py-md px-lg my-lg hover:bg-grey-100 hover:text-grey-900"
        [showDelay]="1000"
        cptTooltip="Workflows"
        routerLinkActive="bg-grey-100 text-grey-900"
        [routerLink]="['workflows']">
        <cpt-svg-icon key="workflow" size="mdlg"></cpt-svg-icon>
        <div class="navbar-item-label text-b2 ml-lg">
          <span class="font-medium">Workflows</span>
        </div>
      </a> -->
    </div>
  </div>
  <div
    class="credits px-xl2 pt-xl2 border-t border-grey-200"
    *ngIf="planInfo$ | async as planInfo">
    <div class="flex justify-between mb-md">
      <div class="text-b1">Credits</div>
      <div class="text-b1 font-light text-grey-400">
        {{ planInfo.nbrUsedCredits | mathAbs }}/{{
          planInfo.nbrAvailableCredits
        }}
      </div>
    </div>
    <cpt-progress-bar
      [value]="planInfo.nbrUsedCredits / planInfo.nbrAvailableCredits"
      [max]="1"
      [gradientStyle]="{
        background:
          'linear-gradient(90deg, #213BE5 0.2%, #00C9D1 32.3%, #E3F419 66.47%, #FE5001 99.6%)'
      }" />

    <div class="mt-lg">
      <a
        *ngIf="planInfo?.currentMainPlan?.id === 'trial-monthly'"
        [routerLink]="['/settings', 'plans']"
        alt="Settings plans">
        <span
          *ngIf="planInfo.trialDaysLeft > 0"
          class="text-b1 text-turquoise-500">
          {{ planInfo.trialDaysLeft }} day{{
            planInfo.trialDaysLeft > 1 ? 's' : ''
          }}
          left</span
        >
        <span
          *ngIf="planInfo.trialDaysLeft < 1"
          class="text-b1 text-orange-700">
          Trial ended - Choose your plan
        </span>
      </a>
    </div>
  </div>
</div>
