import { Entity } from '@models/entity';

export class Workspace extends Entity {
  name: string;
  permalink: string;
  description: string;
  logoUrl: string;
  apiKey: string;
  defaultCurrency: string;
  maxUsersViaApi: number;
  slackWebhook: string;
  timezone: string;
  usersManagementViaApi: boolean;
  autoResumeQueuedRuns: boolean;
  isOnboarded: boolean;
  migrationStatus: 'ready' | 'migrating' | 'migrated';
  accountNotify: boolean;
  accountNotifyCustomHeaders: { name: string; value: string }[] | null;
  accountNotifyWebhookUrl: string | null;
}
