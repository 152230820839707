import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SharedAccountConfigurationComponent } from '@modules/integration/shared-account-configuration/shared-account-configuration.component';
import { SetupWorkspaceComponent } from '@modules/setup-workspace/setup-workspace.component';
import { authGuard } from './core/guards/auth.guard';
import { emailVerifiedGuard } from './core/guards/email-verified.guard';
import { meGuard } from './core/guards/me.guard';
import { setupWorkspaceGuard } from './core/guards/setup-workspace.guard';
import { workspaceOnboardedGuard } from './core/guards/workspace-onboarded.guard';
import { LayoutComponent } from './layout/layout.component';
import { PrivateScopeComponent } from './scope/private/private-scope.component';
import { PublicScopeComponent } from './scope/public/public-scope.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'actions-library' },
  {
    path: 'signed-in-redirect',
    pathMatch: 'full',
    redirectTo: 'actions-library'
  },
  // Public routes
  {
    path: '',
    component: PublicScopeComponent,
    children: [
      {
        path: 'integrations/:integrationPermalink/account/:accountUid',
        component: SharedAccountConfigurationComponent,
        data: {
          layout: 'empty'
        }
      }
    ]
  },
  // Private routes depending on workspace state
  {
    path: 'setup',
    canMatch: [authGuard, meGuard, setupWorkspaceGuard],
    component: SetupWorkspaceComponent
  },
  // {
  //   path: 'migration',
  //   canMatch: [authGuard, meGuard],
  //   component: MigrationDoneComponent
  // },
  // All others private routes
  {
    path: '',
    canMatch: [authGuard, meGuard, workspaceOnboardedGuard],
    component: PrivateScopeComponent,
    children: [
      // Expanded layout
      {
        path: '',
        component: LayoutComponent,
        canMatch: [emailVerifiedGuard],
        data: {
          layout: 'expanded'
        },
        children: [
          {
            path: 'settings',
            data: {
              layout: 'expanded'
            },
            loadChildren: () =>
              import('./modules/settings/settings.module').then(
                (m) => m.SettingsModule
              )
          },
          {
            path: 'actions-library',
            data: {
              layout: 'expanded'
            },
            loadChildren: () =>
              import('./modules/actions-library/actions-library.module').then(
                (m) => m.ActionsLibraryModule
              )
          },
          // {
          //   path: 'templates-library',
          //   providers: [
          //     AppTemplateService,
          //     {
          //       provide: 'mode',
          //       useValue: 'route'
          //     }
          //   ],

          //   data: {
          //     layout: 'expanded'
          //   },
          //   loadComponent: () =>
          //     import('./modules/templates-library/templates-library.route')
          // },
          // {
          //   path: 'templates-library/:workflowUid',

          //   data: {
          //     layout: 'expanded'
          //   },
          //   loadComponent: () => import('./modules/template/template.route')
          // },

          {
            path: 'integrations/:integrationPermalink',
            data: {
              layout: 'expanded'
            },
            loadChildren: () =>
              import('./modules/integration/integration.module').then(
                (m) => m.IntegrationModule
              )
          },
          // {
          //   path: 'integrations',
          //   data: {
          //     layout: 'expanded'
          //   },
          //   loadChildren: () =>
          //     import('./modules/integrations/integrations.module').then(
          //       (m) => m.IntegrationsModule
          //     )
          // },
          // {
          //   path: 'workflows',
          //   data: {
          //     layout: 'expanded'
          //   },
          //   loadChildren: () =>
          //     import('./modules/workflows/workflows.module').then(
          //       (m) => m.WorkflowsModule
          //     )
          // },
          {
            path: 'profile',
            data: {
              layout: 'expanded'
            },
            loadChildren: () =>
              import('./modules/profile/profile.module').then(
                (m) => m.ProfileModule
              )
          }
          // {
          //   path: 'activity',
          //   data: {
          //     layout: 'expanded'
          //   },
          //   loadChildren: () =>
          //     import('./modules/activity/activity.module').then(
          //       (m) => m.ActivityModule
          //     )
          // }
        ]
      }
    ]
  },
  // TODO: renvoyer vers une page 404 not found
  { path: '**', redirectTo: 'actions-library' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      bindToComponentInputs: true,
      paramsInheritanceStrategy: 'always'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
