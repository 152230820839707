import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CptErrorResponse } from '@models/captain/error-response/cpt-error-response';
import { CptSuccessResponse } from '@models/captain/success-response/cpt-success-response';
import { Entity } from '@models/entity';
import { EntitySerializer } from '@models/entity.serializer';
import {
  SRS,
  SRSField,
  SRSFilter,
  SRSInclude,
  SRSSearch,
  SRSSort
} from '@models/srs/srs';
import { Observable } from 'rxjs/internal/Observable';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError } from 'rxjs/internal/operators/catchError';
import { map } from 'rxjs/internal/operators/map';

export abstract class EntityService<T extends Entity> {
  protected readonly options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    }),
    withCredentials: true
  };

  // paginationMetaSerializer = new PaginationMetaSerializer();

  constructor(
    protected httpClient: HttpClient,
    protected url: string, // API URL
    protected resource: string, // Prefix of the resource (Ex: 'workflows', 'users')
    protected serializer: EntitySerializer
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ HTTP requests
  // -----------------------------------------------------------------------------------------------------

  public create(item: T): Observable<CptSuccessResponse<T>> {
    return this.httpClient
      .post<CptSuccessResponse<T>>(
        `${this.url}/${this.resource}`,
        this.serializer.toJSON(item),
        this.options
      )
      .pipe(
        map((response: CptSuccessResponse<T>) => {
          response.content = this.serializer.toEntity(response.content) as T;
          return response;
        }),
        catchError(this.handleError)
      );
  }

  public update(item: T): Observable<CptSuccessResponse<T>> {
    return this.httpClient
      .put<CptSuccessResponse<T>>(
        `${this.url}/${this.resource}/${item.uid}`,
        this.serializer.toJSON(item),
        this.options
      )
      .pipe(
        map((response: CptSuccessResponse<T>) => {
          response.content = this.serializer.toEntity(response.content) as T;
          return response;
        }),
        catchError(this.handleError)
      );
  }

  public updateField(
    item: T,
    field: string
  ): Observable<CptSuccessResponse<T>> {
    field = this.serializer.mapping.get(field) || field;
    /**
     * JSON.stringify(obj, replacer) permet de récuérer seulement l'attribut souhaité de l'objet
     * On retransforme instantanément en Objet avec JSON.parse()
     * Si on laisse en string, cela fonctionne mais le DateInterceptor ne normalize pas les dates
     */
    let uri = `${this.url}/${this.resource.replace('/', '')}/${item.uid}`;
    if (field === 'role_name')
      uri = `${this.url}/${this.resource.replace('/', '')}/${item.uid}/role`;
    return this.httpClient
      .patch<CptSuccessResponse<T>>(
        uri,
        JSON.parse(JSON.stringify(this.serializer.toJSON(item), [field])),
        this.options
      )
      .pipe(
        map((response: CptSuccessResponse<T>) => {
          response.content = this.serializer.toEntity(response.content) as T;
          return response;
        }),
        catchError(this.handleError)
      );
  }

  read(
    id: string,
    srs: SRS<T> | null = null,
    queryParams: any = null
  ): Observable<CptSuccessResponse<T>> {
    let params: HttpParams = new HttpParams({ fromObject: queryParams || {} });
    params = this.addSRSFields(params, srs);
    return this.httpClient
      .get<CptSuccessResponse<T>>(`${this.url}/${this.resource}${id}`, {
        params,
        withCredentials: true
      })
      .pipe(
        map((response: CptSuccessResponse<T>) => {
          response.content = this.serializer.toEntity(response.content) as T;
          return response;
        }),
        catchError(this.handleError)
      );
  }

  list(srs: SRS<T> | null): Observable<CptSuccessResponse<T[]>> {
    let params: HttpParams = new HttpParams();
    params = this.addSRSFields(params, srs);
    return this.httpClient
      .get<CptSuccessResponse<T[]>>(`${this.url}/${this.resource}`, {
        params,
        withCredentials: true
      })
      .pipe(
        map((response: CptSuccessResponse<T[]>) => {
          response.content = this.toEntities(response.content) as T[];
          return response;
        }),
        catchError(this.handleError)
      );
  }

  delete(id: string): Observable<CptSuccessResponse<T>> {
    return this.httpClient
      .delete<CptSuccessResponse<T>>(
        `${this.url}/${this.resource}${id}`,
        this.options
      )
      .pipe(
        map((response: CptSuccessResponse<T>) => {
          return response;
        }),
        catchError(this.handleError)
      );
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Error handler
  // -----------------------------------------------------------------------------------------------------
  protected handleError(error: CptErrorResponse) {
    console.log('handleError', error);
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.messageToString());

      return throwError(() => error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      // Return an observable with a user-facing error message.
      return throwError(() => error);
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * If API returns a list of entities, we need to serialize each object of the JSON array
   *
   * @param data
   * @returns list of entities
   */
  protected toEntities(data: any): T[] {
    return data.map((item: any) => this.serializer.toEntity(item));
  }

  /**
   * Add Standard Response Schema "fields", "search" & "include" to the HttpParams
   *
   * @param params
   * @param fields
   */
  protected addSRSFields(
    params: HttpParams,
    srs: SRS<T> | null,
    allowAnyKey: boolean = false
  ): HttpParams {
    if (srs) {
      // Add search
      if (srs.search?.length) {
        srs.search.forEach((search: SRSSearch<T>) => {
          const apiField: string | undefined = allowAnyKey
            ? search.key
            : this.serializer.mapping.get(search.key);
          if (apiField) {
            params = params.append('search', `${apiField}:${search.value}`);
          }
        });
      }

      // Add sort
      if (srs.sort?.length) {
        srs.sort.forEach((sort: SRSSort<T>) => {
          const apiField: string | undefined = allowAnyKey
            ? sort.key
            : this.serializer.mapping.get(sort.key);
          if (apiField) {
            params = params.append('sort', `${apiField}:${sort.value}`);
          }
        });
      }

      // Add fields
      if (srs.fields?.length) {
        srs.fields.forEach((field: SRSField<T>) => {
          const apiField: string | undefined = allowAnyKey
            ? field.key
            : this.serializer.mapping.get(field.key);
          if (apiField) {
            params = params.append('fields', `${apiField}`);
          }
        });
      }

      // Add include
      if (srs.include?.length) {
        srs.include.forEach((include: SRSInclude<T>) => {
          const apiField: string | undefined = allowAnyKey
            ? include.key
            : this.serializer.mapping.get(include.key);
          if (apiField) {
            params = params.append('include', apiField ?? '');
          }
        });
      }

      // Add Pagination
      if (srs.pagination) {
        params = params.append('page', srs.pagination?.page ?? 0);
        params = params.append(
          'items_per_page',
          srs.pagination?.itemsPerPage ?? 10
        );
      }

      // Add Filter
      if (srs.filters?.length) {
        srs.filters.forEach((filter: SRSFilter<T>) => {
          const apiField: string | undefined = allowAnyKey
            ? filter.key
            : this.serializer.mapping.get(filter.key);
          if (apiField) {
            params = params.append(
              'filter',
              `${apiField}__${filter.operator}__${filter.value}`
            );
          }
        });
      }
    }
    return params;
  }
}
